import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import icon3 from '../../images/homepage/what-is-research/green-circle.svg'
interface CounterI {
  isVisible?: boolean
  text?: string
}

export const Counter = ({ isVisible, text }: CounterI) => {
  let [countVal, setVal] = useState(0)

  useEffect(() => {
    if (!isVisible) {
      setVal(0)
    } else {
      const countUp = () => {
        if (countVal > 33) {
          clearInterval(setTime)
          setVal(33)
        } else {
          setVal(countVal)
          countVal++
        }
      }
      const setTime = setInterval(countUp, 100)
      return () => {
        clearInterval(setTime)
      }
    }
  }, [isVisible])

  return (
    <IconMask>
      <CounterText>
        <LightText className="p-large">{text}</LightText>

        <Count id="count">{countVal}%</Count>
      </CounterText>
    </IconMask>
  )
}
const IconMask = styled.div`
  width: 120px;
  min-width: 120px;
  height: 120px;
  background: url(${icon3});
  background-size: cover;
  position: relative;
  @media (min-width: 768px) {
    width: 180px;
    min-width: 180px;
    height: 180px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    width: 240px;
    height: 240px;
  }
`
const CounterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const LightText = styled.p`
  color: ${theme.text.white};
  font-size: 11px;
  line-height: 14px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`
const Count = styled.p`
  color: ${theme.text.white};
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  @media (min-width: 768px) {
    font-size: 8rem;
    line-height: 6rem;
    margin-top: 6px;
  }
`
