import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SectionHeading from '../shared/SectionHeading'
import theme from '../styling/theme'

import dots from '../../images/homepage/3dots.svg'
import TrackVisibility from 'react-on-screen'
import { Counter } from './Counter'

interface IContent {
  icon: string
  iconAlt: string
  body: string
  lightText?: string
}
interface IWhatIsResearch {
  animation?: boolean
  maxwidth?: string
  content?: Array<IContent>
  tag?: string
  heading?: string
  body?: Array<string>
  isVisible?: boolean
}

const WhatIsResearch = ({
  animation,
  maxwidth,
  content,
  tag,
  heading,
  body,
  isVisible,
}: IWhatIsResearch) => {
  // let [countVal, setVal] = useState(0)

  // useEffect(() => {
  //   if (!isVisible) {
  //     setVal(0)
  //   } else {
  //     const countUp = () => {
  //       if (countVal > 33) {
  //         clearInterval(setTime)
  //         setVal(33)
  //       } else {
  //         setVal(countVal)
  //         countVal++
  //       }
  //     }
  //     const setTime = setInterval(countUp, 100)
  //     return () => {
  //       clearInterval(setTime)
  //     }
  //   }
  // }, [isVisible])

  return (
    <Container>
      <TagContainer maxwidth={maxwidth}>
        {tag && <p className="section-tag">{tag}</p>}
      </TagContainer>
      <TextContainer maxwidth={maxwidth} animation={animation}>
        <Col>{heading && <SectionHeading>{heading}</SectionHeading>}</Col>
        <Col>
          {body.map((item, key) => {
            return (
              <P className="p-large" key={key}>
                {item}
              </P>
            )
          })}
        </Col>
      </TextContainer>
      {animation && (
        <AnimationContainer>
          <Grid>
            {content &&
              content.map((item, key) => {
                return (
                  <Card key={key}>
                    {!item.lightText ? (
                      <Icon src={item.icon} alt={item.iconAlt} />
                    ) : (
                      <TrackVisibility partialVisibility>
                        <Counter text={item.lightText} />
                      </TrackVisibility>
                    )}

                    <Body className="p-large">{item.body}</Body>
                  </Card>
                )
              })}
            <LineContainerMobile>
              <LineMobile />
              <DotsMaskMobile>
                <Dots
                  src={dots}
                  alt="3 Green Dots moving continuous from left to right"
                />
              </DotsMaskMobile>
            </LineContainerMobile>
          </Grid>
          <LineContainer>
            <Line />
            <DotsMask>
              <Dots
                src={dots}
                alt="3 Green Dots moving continuous from left to right"
              />
            </DotsMask>
          </LineContainer>
        </AnimationContainer>
      )}
    </Container>
  )
}

export default WhatIsResearch

const Container = styled.section`
  padding: 40px 20px 0;
  @media (min-width: 768px) {
    overflow-x: hidden;
    padding: 9rem 0 5rem;
  }
`
interface ITagContainer {
  maxwidth?: string
  animation?: boolean
}
const TagContainer = styled.div<ITagContainer>`
  background: white;
  @media (min-width: 768px) {
    width: 100%;
    max-width: ${props => (props.maxwidth ? props.maxwidth : '860px')};
    padding: 0 3%;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    padding: 0;
  }
`

const TextContainer = styled.div<ITagContainer>`
  background: white;
  margin-bottom: ${props => (props.animation ? '0' : '25px')};
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    max-width: ${props => (props.maxwidth ? props.maxwidth : '860px')};
    padding: 0 3%;
    margin: ${props => (props.animation ? '0 auto 48px' : '0 auto')};
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`

const Col = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    &:first-of-type {
      margin-right: 5rem;
    }
  }
`

const AnimationContainer = styled.div`
  margin-top: 20px;
  position: relative;
  padding: 90px 0;
  @media (min-width: 768px) {
    margin-top: 48px;
    padding: 0px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  grid-column-gap: 33px;
  grid-row-gap: 22px;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding-left: 40px;
  position: relative;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10rem;
    padding: 0 3%;
    position: unset;
  }
`
const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    max-width: 240px;
    flex-direction: column;
  }
`

const Icon = styled.img`
  width: 120px;
  display: block;

  @media (min-width: 768px) {
    width: 180px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    width: 240px;
    margin: 0 auto;
  }
`

const Body = styled.p`
  margin-top: 12px;
  margin-left: 10px;
  @media (min-width: 768px) {
    text-align: center;
    max-width: 230px;
  }
`

const LineContainer = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 40%;
  left: 0;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #d8d8d8 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const Line = styled.div`
  width: 100%;
  height: 5px;
  background: repeating-linear-gradient(
    45deg,
    rgba(216, 216, 216, 0.2),
    rgba(216, 216, 216, 0.2) 2px,
    #ffffff 2px,
    #ffffff 4px
  );
`

const DotsMask = styled.div`
  height: 9px;
  display: flex;
  position: relative;
  top: -8px;

  animation: animateDots 7s ease-in-out infinite;
  @keyframes animateDots {
    0% {
      transform: translateX(-10%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`

const Dots = styled.img`
  width: 22px;
  height: 9px;
  transform: rotate(90deg);

  @media (min-width: 768px) {
    transform: rotate(0);
    width: 20px;
    height: 6px;
  }
`

const LineContainerMobile = styled.div`
  display: block;
  position: absolute;
  height: calc(100% + 180px);
  width: 6px;
  top: -90px;
  left: 100px;
  z-index: -2;
  @media (min-width: 768px) {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -2px;
    width: 6px;
    height: 100%;
    background-image: linear-gradient(#d8d8d8 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 12px;
    background-repeat: repeat-y;
  }
`

const LineMobile = styled.div`
  height: 100%;
  width: 5px;
  background: repeating-linear-gradient(
    45deg,
    rgba(216, 216, 216, 0.2),
    rgba(216, 216, 216, 0.2) 2px,
    #ffffff 2px,
    #ffffff 4px
  );
`
const DotsMaskMobile = styled.div`
  height: 100%;
  width: 22px;
  display: flex;
  position: absolute;
  top: -10%;
  left: -8px;
  animation: animateDotsMobile 7s ease-in-out infinite;
  @keyframes animateDotsMobile {
    0% {
      transform: translateY(-10%);
    }
    100% {
      transform: translateY(105%);
    }
  }
`
const P = styled.p`
  &:not(:last-of-type) {
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 20px;
    }
  }
`
