import React from 'react'
import styled from 'styled-components'

interface IVerticalPadding {
  children: any
}

const VerticalPadding = ({ children }) => {
  return <Container>{children}</Container>
}

export default VerticalPadding

const Container = styled.div`
  padding: 25px 0;

  @media screen and (min-width: 1024px) {
    padding: 5rem 0;
  }
`
