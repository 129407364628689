import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from '../styling/theme'

interface InlineLink {
  to?: string
  text?: String
  marginbottom?: string
  margintopDesktop?: string
  margintop?: string
  icon?: boolean
  hovercolor?: string
  center?: boolean
  target?: string
}
const InlineLink = ({
  to,
  text,
  marginbottom,
  margintop,
  margintopDesktop,
  icon,
  hovercolor,
  center,
  target,
}: InlineLink) => {
  return (
    <Wrapper
      marginbottom={marginbottom}
      margintop={margintop}
      margintopDesktop={margintopDesktop}
      center={center}
    >
      <StyledLink to={to} hovercolor={hovercolor} target={target}>
        {text}{' '}
      </StyledLink>
      {icon && (
        <svg
          id="arrow"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 43.96 20.54"
        >
          <g id="Designs">
            <polygon
              id="Path"
              points="33.69 20.54 43.96 10.27 33.69 0 32.48 1.21 40.69 9.41 0 9.41 0 11.13 40.69 11.13 32.48 19.34 33.69 20.54"
            />
          </g>
        </svg>
      )}
    </Wrapper>
  )
}

export default InlineLink

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.margintop && props.margintop};
  margin-bottom: ${props => props.marginbottom && props.marginbottom};
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  & #arrow {
    width: 35px;
    fill: #008d36;
    margin-left: 10px;
    transition: margin 0.3s ease;
  }
  @media (min-width: 768px) {
    margin-top: ${props => props.margintopDesktop && props.margintopDesktop};
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  &,
  &:link,
  &:visited {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: ${theme.brand.blue};

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  &:hover,
  &:active {
    color: ${props => (props.hovercolor ? props.hovercolor : theme.text.main)};
  }

  &:hover ~ #arrow {
    fill: #000000;
    margin-left: 20px;
  }
`
